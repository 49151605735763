import styled from 'styled-components'
import BreakpointUp from '../Media/BreakpointUp'

export const Nodata = styled.div`
  position: relative;
  text-align: center;
  background-color:#fff;
  display:flex;
  align-items: center;
  justify-content: center;
  box-shadow:0 25px 55px rgba(0,75,155,0.2);
  width: 100%;
  border-radius: 4px;
`

export const NodataContainer= styled.div`
    max-width:770px;
    width:100%;
    margin:0 auto;
    padding: 90px 30px;
    position: relative;
    ${BreakpointUp.xl`
        padding: 150px 30px;
    `}
    ${BreakpointUp.xxl`
        padding: 200px 30px;
    `}
`
export const NodataMedia = styled.div`
  position: relative;
  margin-bottom:30px;
`

export const NodataTitle = styled.h2`
    margin-bottom:20px;
`
export const NodataDesc = styled.div`
  p{
    a{
      color:#D51333;
      font-weight:700;
      &:hover{
        color:#033366;
      }
    }
  }
`
export const NodataBg = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0px;
  opacity:0.30;
  .gatsby-image-wrapper{
    display: block;
  }
`