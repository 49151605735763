import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import Layout from "../layouts"
import Seo from "../components/seo"
import { Link } from "gatsby"

import {
  BreadCrumb,
  Container,
  Section,
  SectionTitle,
} from "../components/Section"
import {
  Nodata,
  NodataContainer,
  NodataMedia,
  NodataTitle,
  NodataDesc,
  NodataBg,
} from "../components/Nodata"
import ArticleCard from "../components/ArticleCard"
import BreakpointUp from "../components/Media/BreakpointUp"
import Badge from "../components/Badge"
import BreakpointDown from "../components/Media/BreakpointDown"

const SectionGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
`
const SectionGridCol = styled.div`
  position: relative;
  width: 100%;
  padding: 0 10px;
  ${BreakpointUp.lg` 
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    `}
  ${BreakpointDown.md`
     &:last-child{
         .review-card{
             margin-bottom:0;
         }
     }
    `}
`

const MainPanel = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -15px;
  ${BreakpointUp.lg`
        margin:0 -30px;
    `}
  ${BreakpointUp.xxl`
        margin:0 -50px;
    `}
    ${BreakpointDown.md`
      margin:0 -10px;
    `}
`

const LeftSidbar = styled.div`
  position: relative;
  width: 100%;
  padding: 0 15px;
  ${BreakpointUp.lg`
        flex: 0 0 25%;
        max-width: 25%;
        padding:0 30px;    
    `}
  ${BreakpointUp.xl`
        flex: 0 0 33.333333%;
        max-width: 33.333333%;  
    `}
    ${BreakpointUp.xxl`
        padding:0 50px;
    `} 
    ${BreakpointDown.md`
      padding:0 10px;
    `}
`
const MainContent = styled.div`
  position: relative;
  width: 100%;
  padding: 0 15px;
  ${BreakpointUp.lg`
        flex: 0 0 75%;
        max-width: 75%;
        padding:0 30px;
    `}
  ${BreakpointUp.xl`
        flex: 0 0 66.666667%;
        max-width: 66.666667%;        
    `}
    ${BreakpointUp.xxl`
        padding:0 50px;
    `}
`
const ArticleList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`
const ArticleListItem = styled.div`
  position: relative;
  width: 100%;
  &:not(:last-child) {
    margin-bottom: 30px;
  }
  ${BreakpointUp.lg` 
       &:not(:last-child){
          margin-bottom:60px;
        }
    `}
  ${BreakpointDown.md`
       &:not(:last-child){
          margin-bottom:30px;
        } 
    `}
    .card {
    height: auto;
    ${BreakpointUp.md`
            display: flex;
            flex-wrap:wrap;
            margin:0 -15px;
        `}
    ${BreakpointUp.xxl`
            padding:70px 90px;
        `}
        .card-body {
      position: relative;
      width: 100%;
      ${BreakpointUp.md`
                flex: 0 0 50%;
                max-width: 50%;
            `}
    }
    .card-img {
      position: relative;
      width: 100%;
      ${BreakpointUp.md`
                flex: 0 0 50%;
                max-width: 50%;
            `}
    }
  }
`
const ListGroupHeading = styled.h1`
  color: #000;
  font-family: "Barlow", sans-serif;
  font-weight: 700;
  margin: 0 0 15px;
  letter-spacing: 0.3px;
  font-size: 28px;
  line-height: 36px;
  @media (min-width: 768px) {
    font-size: 32px;
    line-height: 42px;
  }
  ${BreakpointUp.xxl`
      font-size: 42px;
      line-height: 54px;
    `}
`
const GroupFlush = styled.div`
  ${BreakpointDown.md`
     margin-bottom:30px;
    `}
`
const ListGroupFlush = styled.div`
  font-family: "Barlow", sans-serif;
  font-weight: 700;
  letter-spacing: 0.3px;
  margin: 0 0 5px;
  ${BreakpointUp.md`
        margin:0 0 15px; 
    `}
  ${BreakpointUp.xl`
        font-size: 20px;
        line-height: 24px;
    `}
    ${BreakpointUp.xxl`
      font-size: 22px;
      line-height: 26px;
    `}
`
const ListGroupItem = styled.div`
  border-bottom: 1px solid #ccd6e0;
  color: #444;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 15px;
  ${BreakpointUp.xl`
      padding: 15px;
    `}
  &.active, &:hover {
    color: #000;
    background-color: #fff;
  }
  ${BreakpointUp.xl`
      padding: 20px 20px 20px 40px;
    `}
  ${BreakpointUp.xxl`
      padding: 25px 20px 25px 40px;
    `}
`

const BlogPage = ({ location, data }) => {
  const allBlogCategories = data.allContentfulBlogCategory.edges
  const allBlogs = data.allContentfulBlog.edges
  const [activeCategory, setActiveCategory] = useState("All")
  const [blogs, setBlogs] = useState(allBlogs)
  useEffect(() => {
    if (activeCategory !== "All") {
      const currentBlogs = allBlogs.filter(
        item => item.node.category.name === activeCategory
      )
      setBlogs(currentBlogs)
    } else {
      setBlogs(allBlogs)
    }
  }, [activeCategory, setActiveCategory])
  return (
    <Layout isHeader={true} location={location}>
      <Seo
        title="Trending Blogs and Articles on Metal Buildings"
        description="Check out the latest trends and information on the metal buildings and steel structures industry. "
      />
      <BreadCrumb>
        <Container maxWidth="100%" pl="3%" pr="3%">
          <Link to="/">Home</Link>
          <span>Blog</span>
        </Container>
      </BreadCrumb>
      <Section
        xpt="170px"
        mpt="150px"
        pt="120px"
        xpb="100px"
        mpb="60px"
        pb="30px"
        bgColor="#F0F8FF"
        bdrColor="#CCD6E0"
        bdrWidth="1px"
      >
        <Container maxWidth="100%" pl="3%" pr="3%">
          <SectionTitle mb="10px">
            Our Popular Knowledgeable Articles
          </SectionTitle>
          <SectionGrid>
            {allBlogs.map((item, i) => {
              if (i < 3) {
                return (
                  <SectionGridCol key={i}>
                    <ArticleCard data={item.node} />
                  </SectionGridCol>
                )
              }
              return <></>
            })}
          </SectionGrid>
        </Container>
      </Section>
      <Section
        xpt="90px"
        mpt="60px"
        pt="30px"
        xpb="90px"
        mpb="60px"
        pb="30px"
        bgColor="#F0F8FF"
      >
        <Container maxWidth="100%" pl="3%" pr="3%">
          <MainPanel>
            <LeftSidbar>
              <GroupFlush className="isSticky">
                <ListGroupHeading>Category</ListGroupHeading>
                <ListGroupFlush>
                  <ListGroupItem
                    className={activeCategory === "All" ? "active" : ""}
                  >
                    All{" "}
                    {activeCategory === "All" ? (
                      <span className="badge-sm">
                        <Badge text={allBlogs.length} />
                      </span>
                    ) : (
                      <></>
                    )}
                  </ListGroupItem>
                  {allBlogCategories.map((item, i) => {
                    return (
                      <ListGroupItem
                        onClick={() => setActiveCategory(item.node.name)}
                        className={
                          activeCategory === item.node.name ? "active" : ""
                        }
                      >
                        {item.node.name}
                        {activeCategory === item.node.name ? (
                          <span className="badge-sm">
                            <Badge text={blogs.length} />
                          </span>
                        ) : (
                          <></>
                        )}
                      </ListGroupItem>
                    )
                  })}
                </ListGroupFlush>
              </GroupFlush>
            </LeftSidbar>
            <MainContent>
              <ArticleList>
                {blogs.length > 0 ? (
                  <>
                    {blogs.map((item, i) => (
                      <ArticleListItem key={i}>
                        <ArticleCard data={item.node} />
                      </ArticleListItem>
                    ))}
                  </>
                ) : (
                  <>
                    <Nodata>
                      <NodataBg>
                        <StaticImage
                          src="../images/vecteezy-backgrond.jpg"
                          placeholder="blurred"
                          quality={95}
                          formats={["AUTO", "WEBP", "AVIF"]}
                          alt="not-found-bg"
                        />
                      </NodataBg>
                      <NodataContainer>
                        <NodataMedia>
                          <StaticImage
                            src="../images/coming-soon.png"
                            placeholder="blurred"
                            quality={95}
                            formats={["AUTO", "WEBP", "AVIF"]}
                            alt="coming-soon"
                          />
                        </NodataMedia>
                        <NodataTitle>Blog Coming Soon!</NodataTitle>
                        <NodataDesc>
                          <p>
                            The blog for this category is almost ready. In the
                            meantime, connect with our friendly building
                            specialists at{" "}
                            <a href="tel:8004407309" aria-label="Phone">
                              (800) 440-7309
                            </a>{" "}
                            to know more about your dream metal building.
                          </p>
                        </NodataDesc>
                      </NodataContainer>
                    </Nodata>
                  </>
                )}
              </ArticleList>
            </MainContent>
          </MainPanel>
        </Container>
      </Section>
    </Layout>
  )
}

export default BlogPage

export const query = graphql`
  query BlogPageQuery {
    allContentfulBlog {
      edges {
        node {
          title
          url
          category {
            name
          }
          publishDate(fromNow: true)
          description {
            childMarkdownRemark {
              html
            }
          }
          image {
            gatsbyImageData(quality: 100, placeholder: BLURRED)
          }
          content {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulBlogCategory {
      edges {
        node {
          name
        }
      }
    }
  }
`
